import React from 'react';
import styled from 'styled-components';

const ImageFull = styled.img`
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
`

export default ImageFull;