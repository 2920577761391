import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout'
import styled from 'styled-components'
import BaseGrid from '../components/base-grid'
import Img from 'gatsby-image'
import DescriptionBox from '../components/description-box'
import ImageFull from '../components/image-full';
import logoSvg from '../images/koupit.svg';
import { BUY_LINK } from '../contants'

const BaseGrid10 = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: auto;
`


const Intro = styled.div`
  backround-color: black;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  height: calc(100vh - 20px);
  width: 100%;
`

const Scans = styled.div`
  margin-top: 50px;
`

export default ({ data: { hrbet, scans, photos }, pageContext: { description } }) => {
  return (
    <Layout fixedBottom={
      <a target="_blank" href={BUY_LINK}>
        <ImageFull src={logoSvg} />
      </a>
    }>
      {/* <BaseGrid10>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
          return (
            <Intro key={i} url={hrbet.nodes[0].childImageSharp.fluid.src} />
          )
        })}
      </BaseGrid10> */}
      <Scans>
        {scans.nodes.map((p, i) => {
          return (
            <Img style={{ width: '60%' }} key={i} fluid={p.childImageSharp.fluid} />
          )
        })}
      </Scans>
      <BaseGrid>
        {photos.nodes.map((p, i) => {
          return (
            <Img key={i} fluid={p.childImageSharp.fluid} />
          )
        })}
      </BaseGrid>
      <DescriptionBox text={description} />
    </Layout>
  )
}

export const query = graphql`
  query KDMSK209292929MK556332178AM($hrbetGlob: String!, $scansGlob: String!, $photosGlob: String!){
    hrbet: allFile(sort: {fields: relativePath}, filter: {relativePath: {glob: $hrbetGlob}}) {
      nodes {
        childImageSharp {
          fluid {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    scans: allFile(sort: {fields: relativePath}, filter: {relativePath: {glob: $scansGlob}}) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 1200) {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    photos: allFile(sort: {fields: relativePath}, filter: {relativePath: {glob: $photosGlob}}) {
      nodes {
        childImageSharp {
          fluid {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`