import React from 'react'
import styled from 'styled-components'
import { BUY_LINK } from '../contants'
import A from './a'

const Container = styled.div`
  position: fixed;
  right: 10%;
  bottom: 100px;
  width: 500px;
  border: 1px solid white;
  background: black;
  padding: 15px 15px 10px 15px;
  font-size: 20px;
`

const Separator = () => {
  return (
    <div style={{
      borderTop: '1px solid white',
      margin: '10px -15px',
    }}></div>
  )
}

const DescriptionBox = ({ text }) => {
  const lines = text.split('\n')

  return (
    <Container>
      {lines.map((l, i) => {
        if (l.trim() === '---') {
          return <Separator key={i} />
        }
        return <div key={i}>{l}</div>
      })}
      <Separator />
      <A target="_blank" href={BUY_LINK}>KOUPIT</A>
    </Container>
  )
}


export default DescriptionBox